/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import Spinner from '../components/Spinner';
import * as api from '../utils/api';
import { useCallback } from 'react';
import * as moment from 'moment';
import { formatPrice } from '../utils/formatters';
import BusTicketsFromRl from '../components/BusTicketsFromRl';

const CardProp = ({ label, value }) => (
  <>
    <div sx={{ fontWeight: 'medium', gridColumn: 1 }}>{label}</div>
    <div>{value}</div>
  </>
);

const TwoColumnGrid = ({ children }) => {
  return (
    <Box
      sx={{
        my: 4,
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        columnGap: 4,
        rowGap: 1,
        textAlign: 'left',
      }}
    >
      {children}
    </Box>
  );
};

const CardDetails = ({ card }) => {
  const {
    cardNumber,
    product,
    productName,
    ticketCategory,
    travelLength,
    journeys,
    cardValidUntil,
    balance,
    validStart,
    validEnd,
  } = card;

  const translate = getTranslate(useSelector(state => state.localize));
  const hasProduct = !!product && !!ticketCategory;
  return (
    <TwoColumnGrid>
      <CardProp label={translate('account.myCards.cardNumber')} value={formatCardNumber(cardNumber)} />
      <CardProp label={translate('account.myCards.cardValidUntil')} value={moment(cardValidUntil).format('D.M.YYYY')} />
      {hasProduct ? (
        <>
          <CardProp label={translate('account.myCards.product')} value={productName} />
          <CardProp
            label={translate('account.myCards.productValidUntil')}
            value={moment(validEnd).format('D.M.YYYY')}
          />
          {typeof balance === 'number' ? (
            <CardProp label={translate('account.myCards.balance')} value={formatPrice(balance)} />
          ) : null}
          {!!travelLength && (
            <CardProp label={translate('account.myCards.travelLength')} value={travelLength + ' km'} />
          )}
          {!!journeys && (
            <CardProp
              label={translate('account.myCards.journeys')}
              value={journeys + ' ' + translate('account.myCards.piece')}
            />
          )}
        </>
      ) : (
        <div sx={{ fontWeight: 'medium', gridColumn: '1 / span 2' }}>{translate('account.myCards.noProduct')}</div>
      )}
    </TwoColumnGrid>
  );
};

function isValidCardNumber(cardNumber) {
  if (!cardNumber) return false;
  const trimmed = cardNumber.replaceAll(' ', '');
  return /^[MF]\d{18}$/.test(trimmed);
}

const formatCardNumber = cardNumber => {
  const n = cardNumber.length;
  let result = '';
  for (let i = 1; i <= n; i++) {
    result = cardNumber[n - i] + result;
    if (i % 3 === 0 && i < n - 1) {
      result = ' ' + result;
    }
  }
  return result;
};

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  // const [isLoading, setLoading] = useState(false);
  const [travelCards, setTravelCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [cardDetails, setCardDetails] = useState();
  const [customOptions, setCustomOptions] = useState([]);

  /*
  useEffect(() => {
    (async () => {
      let cards;
      try {
        setLoading(true);
        cards = await api.getTravelCards();
      } finally {
        setLoading(false);
      }
      setTravelCards(cards?.idTokens || []);
      const firstCard = cards?.idTokens?.[0];
      // setSelectedCard(firstCard);
      setCardDetails();
    })();
  }, [setTravelCards]);
  */

  const selectCard = useCallback(
    optionOrNull => {
      const { value } = optionOrNull || {};
      setCardDetails();
      setSelectedCard(value);
    },
    [setSelectedCard]
  );

  useEffect(() => {
    (async () => {
      if (!selectedCard) return;
      const details = await api.getTravelCardDetails(selectedCard);
      setCardDetails(details);
    })();
  }, [selectedCard]);

  const options = useMemo(() => {
    return [...customOptions, ...(travelCards || [])].map(cardId => ({
      value: cardId,
      label: formatCardNumber(cardId),
    }));
  }, [travelCards, customOptions]);

  const handleCreate = useCallback(newValue => {
    const trimmed = newValue.replaceAll(' ', '');
    if (trimmed === '') return;
    setCustomOptions([...customOptions, trimmed]);
    setCardDetails();
    setSelectedCard(trimmed);
  });

  const searchNewLabel = useMemo(
    () => cardNumber => {
      const trimmed = cardNumber.replaceAll(' ', '');
      return translate('account.myCards.search') + ' ' + formatCardNumber(trimmed);
    },
    [translate]
  );

  return (
    <Box>
      <Styled.h1>{translate('account.myCards.title')}</Styled.h1>
      <Styled.p>{translate('account.myCards.ingress')}</Styled.p>

      {!travelCards && (
        <div sx={{ position: 'relative', height: '100px' }}>
          <Spinner />
        </div>
      )}
      <div sx={{ maxWidth: '300px' }}>
        {/*
        <span sx={{ fontWeight: 'medium' }}>{translate('account.myCards.choose')}</span>
         */}
        <CreatableSelect
          isClearable
          options={options}
          onChange={selectCard}
          onCreateOption={handleCreate}
          value={options.find(o => o.value === selectedCard)}
          isValidNewOption={isValidCardNumber}
          formatCreateLabel={searchNewLabel}
          placeholder={translate('account.myCards.enter')}
          isOptionSelected={option => option.value === selectedCard}
        />
      </div>
      {cardDetails && !cardDetails.found && (
        <Styled.p
          sx={{
            my: 4,
          }}
        >
          {translate('account.myCards.notFound')}
        </Styled.p>
      )}
      {cardDetails?.found && <CardDetails card={cardDetails} />}

      <Box sx={{ mt: 4 }}>
        <BusTicketsFromRl type="myCards" />
      </Box>
    </Box>
  );
};
